// src/components/Publications.js
import React from 'react';
import { Container, Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-scroll';
import { useTranslation } from 'react-i18next';
import '../css/Publications.css';

function Publications() {
  const { t } = useTranslation();

  const journalArticles = t('journal_articles', { returnObjects: true });
  const submittedArticles = t('submitted_articles', { returnObjects: true });

  return (
    <Container>
      <Row className="publications-nav mb-4">
        <Col xs={12} sm={6} md={4} lg={4} className="publication-item mb-2">
          <Nav.Link as={Link} to="journal" smooth duration={200} className="publications-link publications-journal">
            {t('publications_journal')}
          </Nav.Link>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="publication-item mb-2">
          <Nav.Link as={Link} to="submitted" smooth duration={200} className="publications-link publications-submitted">
            {t('publications_submitted')}
          </Nav.Link>
        </Col>
        <Col xs={12} sm={6} md={4} lg={4} className="publication-item mb-2">
          <Nav.Link as={Link} to="misc" smooth duration={200} className="publications-link publications-misc">
            {t('publications_misc')}
          </Nav.Link>
        </Col>
      </Row>

      <section id="journal" className="publication-section">
        <h2>{t('publications_journal')}</h2>
        <ol>
          {journalArticles.map((article, index) => (
            <li key={index}>
              {article.authors}
              <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
              {`, ${article.journal}`}
            </li>
          ))}
        </ol>
      </section>

      <section id="submitted" className="publication-section">
        <h2>{t('publications_submitted')}</h2>
        {/* Начало нумерации с последнего номера предыдущего списка + 1 */}
        <ol start={journalArticles.length + 1}>
          {submittedArticles.map((article, index) => (
            <li key={index}>
              {article.authors}
              {article.link ? (
                <a href={article.link} target="_blank" rel="noopener noreferrer">{article.title}</a>
              ) : (
                <span>{article.title}</span>
              )}
              {`, ${article.journal}`}
            </li>
          ))}
        </ol>
      </section>
      
      <section id="misc" className="publication-section">
        <h2>{t('publications_misc')}</h2>
        <ol>
            <li>
              <a
                href={`${process.env.PUBLIC_URL}/docs/diploma.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Diploma
              </a>
            </li>
            <li>
              <a
                href={`${process.env.PUBLIC_URL}/docs/dissertation.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Dissertation
              </a>
            </li>
            <li>
              <a
                href={`${process.env.PUBLIC_URL}/docs/higher_Education_Teaching_Certificate.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Harvard Teaching Certificate
              </a>
            </li>
          </ol>
      </section>
    </Container>
  );
}

export default Publications;
